import clsx from 'clsx'

export default function MapListingCardSkeleton({
  className,
}: {
  className?: string
}) {
  return (
    <div className={clsx(className, 'bg-background rounded-md shadow-md')}>
      <div className="animate-pulse">
        <div className="rounded-t-md aspect-video bg-muted" />
        <div className="p-4">
          <div className="h-6 mb-2 bg-muted rounded"></div>
          <div className="h-5 mb-1 bg-muted rounded"></div>
          <div className="h-5 mb-2 bg-muted rounded"></div>
          <div className="flex gap-2">
            <div className="h-6 bg-muted rounded w-24" />
            <div className="h-6 bg-muted rounded w-24" />
            <div className="h-6 bg-muted rounded w-24" />
          </div>
        </div>
      </div>
    </div>
  )
}
